import React from "react"
import { withPrefix } from "gatsby"
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.toggle = this.toggle.bind(this);
    
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
    <Container fluid="true" className="header">

       <Navbar light expand="md">
          <NavbarBrand className="header-brand" href="/" ><img src={withPrefix('/assets/img/interaid-logo.png')} alt="Logo" width="120px" class="img-fluid"/></NavbarBrand>
          <NavbarToggler  onClick={this.toggle}  />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
            <NavItem>
              
                <NavLink href="/about">About</NavLink>
              </NavItem>
              <NavItem>

                <NavLink href="/service">Service</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/event">Event</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact/">Contact Us</NavLink>
              </NavItem>
             
               <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle tag="a" className="nav-link" caret>
                  E-shop
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" href="https://www.lazada.com.my/shop/interaid-pharmacy/"  target="_blank">Lazada</DropdownItem>
                  <DropdownItem tag="a" href="https://shopee.com.my/interaid_pharmacy"  target="_blank" >Shopee</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
       
            </Nav>
          </Collapse>
        </Navbar>

  </Container>

    
 
      );
  }
}
// isOpen={this.state.isOpen}
//  onclick={this.toggle} 
// const Header = () => (  
   
// )
    /*<Row>
          <Col md="3" ><img src={withPrefix('/assets/img/interaid-logo.png')} alt="Logo" width="50%"/></Col>
          <Col md="9" className="text-right">something</Col>
        </Row>*/

export default Header
// https://shopee.com.my/interaid_pharmacy
//  <NavLink href="https://www.lazada.com.my/shop/interaid-pharmacy/" target="_blank">E-Shop</NavLink>

  // constructor(props) {
  //   super(props);
  //   this.toggle = this.toggle.bind(this);
  //   this.state = {
  //     isOpen: false
  //   };
  // }

  // toggle() {
  //   this.setState({
  //     isOpen: !this.state.isOpen
  //   });
  // }