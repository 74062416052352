// import './bootstrap.min.css';
import React from "react";
import { Helmet } from "react-helmet"
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../node_modules/bootstrap-4-theme/dist/bootstrap-theme.css";
import "../../node_modules/react-alice-carousel/lib/alice-carousel.css";
import "../../node_modules/animate.css/animate.min.css";

import Header from "../components/Header";
import NoticeBanner from "../components/NoticeBanner";
import Footer from "../components/Footer";

const Layout = ({ children }) => (
    <div class="layout" style = {{}}>
        <Helmet>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
          <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
          <title>Interaid Pharmacy | Your most trusted drugstore</title>
          <meta name="title" content="Interaid Pharmacy | Your most trusted drugstore" />
          <meta name="description" content="The legacy of a local, independent pharmacy continues and strives with Interaid Pharmacy. Visit us and experience good ole down-home service." />
          <link rel="canonical" href="http://www.interaidpharmacy.com.my" />

        </Helmet>

    	<NoticeBanner/>
    	<Header/>
    	<div>{children}</div>
    	<Footer/>
  	</div>
  )
  
export default Layout
//  style={{marginTop:"110px"}}
// list of components::
// header
// navigation
// {children}
// footer

// <link rel="icon" href="http://example.com/favicon.png" />