import React from "react"

const NoticeBanner = () => (  
   <div class="notice-banner">
   	{/*<p>Welcome to Interaid Pharmacy. We are open from 10 am to 9.30pm, Tuesday to Sunday.</p>

   	<marquee>Welcome to Interaid Pharmacy. We are open from 10 am to 9.30pm, Tuesday to Sunday.</marquee>
   */}
   <p>Welcome to Interaid Pharmacy. We are open from 10 AM to 9.30 PM, Tuesday to Sunday.</p>

   	<marquee>Welcome to Interaid Pharmacy. We are open from 10 AM to 9.30 PM, Tuesday to Sunday.</marquee>

  </div>
)

export default NoticeBanner