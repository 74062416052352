import React from "react"
import { Container, Row, Col } from "reactstrap"
import { IconContext } from "react-icons"
import { withPrefix } from "gatsby"
import { FaBuilding, FaPhone,FaEnvelope, FaClock} from 'react-icons/fa';

// const Footer = () => (  
class Footer extends React.Component {
	constructor(props) {
	    super(props);
	  }

	  viewDesktopVersion() {
	  	// alert('hey')
	  	var viewport = document.querySelector("meta[name=viewport]");
		viewport.setAttribute('content', 'width=1024');
     
    }


render() {
	return (
	<div class="footer">
<div class="scrn-lg">
   <div >
    <Container fluid="true" className="footer-main">
    	<Row>
			<IconContext.Provider value={{ color: "#d1d2d5"}}>
			<Col md={{ size: 4, offset: 1 }} >

				<p class="text-bold-500 text-white font-18">Contact Us</p>
				<p class="thin-p">Interaid Pharmacy</p>
				<p class="thin-p">
					<Row>
						<Col sm="1"><FaBuilding /> </Col>
						<Col sm ="11">51, Jalan Sentral 2, <br/>
			    		Taman Nusa Sentral 79100 <br/>
			    		Iskandar Puteri, Johor.
			    		</Col>
			    	</Row>
		    	</p>

				<span/>
					<Row>
						<Col sm="1"><FaPhone /> </Col>
						<Col sm ="11">07-5950581</Col>
			    	</Row>
			    <span/>

				
					<Row>
						<Col sm="1"><FaEnvelope /> </Col>
						<Col sm ="11">interaidpharmacy@hotmail.com</Col>
			    	</Row>
			    <span/>

			    <p class="thin-p">
					<Row>
						<Col sm="1"><FaClock /> </Col>
						<Col sm ="11">Tuesday - Sunday: 10.00am to 9.30pm<br/> (Closed on Monday)</Col>
			    	</Row>
			    </p>

			</Col>
			<Col md="4">
				<p class="text-bold-500 text-white font-18">General</p>
				<p class="thin-p"><a href="/about">About</a></p>
				<p class="thin-p"><a href="/service">Service</a></p>
				<p class="thin-p"><a href="/event">Event</a></p>
				<p class="thin-p"><a href="/membership">Prime Membership</a></p>
				<p class="thin-p"><a href="/contact">Contact Us</a></p>
			</Col>
			<Col md={{ size: 2}}>
				<Row>
				<Col>
					<p class="text-bold-500 text-white font-18">Follow Us</p>
					<p class="thin-p"><a href="https://www.facebook.com/InteraidPharmacy" target="_blank">Facebook</a></p>
					<p class="thin-p"><a href="https://www.lazada.com.my/shop/interaid-pharmacy/" target="_blank">Lazada</a></p>
					<p class="thin-p"><a href="https://shopee.com.my/interaid_pharmacy" target="_blank">Shopee</a></p>
				</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<p class=" thin-p text-bold-500 text-white font-18">We Accept</p>
						<img src={withPrefix('/assets/img/payment-method.png')} width="230px" class="img-fluid"/>
					</Col>
				</Row> 
			</Col>
			</IconContext.Provider>
    	</Row>
		<br/>


    </Container>
	
  </div>
   <div class="text-center font-12 text-bold copyright text-white"><span style={{color:"#6da267"}}>COPYRIGHT</span> &#9400; {new Date().getFullYear()} INTERAID PHARMACY. <span style={{color:"#6da267"}}>ALL RIGHTS RESERVED.</span></div>
  

</div>

	<div className ="scrn-mb text-center">
	    <Container fluid="true" className ="footer-main">

			
    	<Row>
    	<Col xs="6" >

				<p class="text-bold-500 text-white font-18">Contact Us</p>
				
				<p class="thin-p">
					<Row>
						<Col md="1"><FaBuilding /> </Col>
						<Col md ="11"><span class="text-bold">Interaid Pharmacy</span><br/>51, Jalan Sentral 2, <br/>
			    		Taman Nusa Sentral 79100 <br/>
			    		Iskandar Puteri, Johor
			    		</Col>
			    	</Row>
		    	</p>

				<p class="thin-p">
					<Row>
						<Col md="1"><FaPhone /> </Col>
						<Col md ="11">07-5950581</Col>
			    	</Row>
			    </p>

				<p class="thin-p">
					<Row>
						<Col md="1"><FaEnvelope /> </Col>
						<Col md ="11">interaidpharmacy@hotmail.com</Col>
			    	</Row>
			    </p>

			   

			</Col>

    	<Col xs="6">
				<p class="text-bold-500 text-white font-18">General</p>
				<p class="thin-p"><a href="/about">About</a></p>
				<p class="thin-p"><a href="/service">Service</a></p>
				<p class="thin-p"><a href="/event">Event</a></p>
				<p class="thin-p"><a href="/membership">Prime Membership</a></p>
				<p class="thin-p"><a href="/contact">Contact Us</a></p>
			</Col>
			
			</Row>
			<br/>
			<br/>
			<a class="font-14 desk-view" onClick={this.viewDesktopVersion}>View Desktop Version</a>
	    	<br/>
	    	<br/>
	    	</Container>
   	<div class="text-center font-12 text-bold copyright text-white"><span style={{color:"#6da267"}}>COPYRIGHT</span> &#9400; {new Date().getFullYear()} INTERAID PHARMACY. <span style={{color:"#6da267"}}>ALL RIGHTS RESERVED.</span></div>
	    	 
	    </div>

	    	
    	 </div>

	)
	}
}

// this.viewDesktopVersion

export default Footer

